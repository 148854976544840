import React, { useState, useEffect } from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"

const UpdateModal = ({
  syncDate,
  isOpen,
  toggle,
  selectedItem,
  mode,
  stageList,
  users,
}) => {
  const isEditMode = mode === "edit"
  if (!isEditMode) {
    selectedItem = {}
  }

  const initialValues = {
    stage: isEditMode ? selectedItem.StageID : "",
    date: isEditMode ? selectedItem.CreatedAt : "",
    inspector: isEditMode ? selectedItem.InspectorID : "",
    statusID: isEditMode ? selectedItem.StatusID : "",
    comment: isEditMode ? selectedItem.HoldNote : "",
    referenceNo:  "",
  }

  const status = [
    { value: "1", label: "Confirm" },
    { value: "4", label: "Hold" },
  ]

  const validationSchema = Yup.object({
    stage: Yup.string().nullable().required("Stage is required"),
    date: Yup.date().required("Date is required"),
    inspector: Yup.string().nullable().required("Inspector is required"),
    statusID: Yup.string().nullable().required("State is required"),
    referenceNo:!isEditMode&&Yup.number().nullable().required("ReferenceNo is required"),
    comment: Yup.string().when("statusID", {
      is: statusID => statusID && statusID === "4",
      then: Yup.string()
        .required("Comment is required when status is Hold")
        .max(500, "Maximum 500 characters allowed"),
      otherwise: Yup.string().max(500, "Maximum 500 characters allowed"),
    }),
  })

  const handleSubmit = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ID: isEditMode ? selectedItem.id : undefined,
      ReferenceNo: !isEditMode ? values.referenceNo : undefined,
      InspectorID: values.inspector,
      StageID: values.stage,
      Date: values.date,
      StatusID: values.statusID,
      HoldNote: values.comment,
    }
    const url=("https://rd0.cpvarabia.com/api/Visits/VisitCont.php")

    axios
      .post(url, formData)
      .then(res => {
        syncDate()
        toggle()
      })
      .catch(error => {})
  }

  

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {isEditMode ? "Edit Visit" : "Add Visit"}
        </ModalHeader>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}    
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              {console.log("errors",errors)
              }
              <ModalBody>
                {!isEditMode && (
                  <FormGroup>
                    <Label for="referenceNo">ReferenceNo</Label>
                    <Input
                      id="referenceNo"
                      type="number"
                      name="referenceNo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.referenceNo && errors.referenceNo && (
                      <div className="text-danger">{errors.referenceNo}</div>
                    )}
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="stage">Stage</Label>
                  <Select
                    id="stage"
                    options={stageList}
                    defaultValue={
                      stageList?.find(
                        option => option.value == selectedItem?.StageID
                      ) 
                    }
                    onChange={option => setFieldValue("stage", option?.value)}
                    onBlur={handleBlur("stage")}
                    isClearable
                  />
                  {touched.stage && errors.stage && (
                    <div className="text-danger">{errors.stage}</div>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="date">Date</Label>
                  <Input
                    type="date"
                    id="date"
                    name="date"
                    value={values.date.split(" ")[0]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.date && !!errors.date}
                  />
                  {touched.date && errors.date && (
                    <div className="text-danger">{errors.date}</div>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="inspector">Inspector</Label>
                  <Select
                    id="inspector"
                    options={users}
                    defaultValue={
                      users?.find(
                        option => option.value === selectedItem?.InspectorID
                      ) || ""
                    }
                    onChange={option =>
                      setFieldValue("inspector", option?.value)
                    }
                    onBlur={handleBlur("inspector")}
                    isClearable
                  />
                  {touched.inspector && errors.inspector && (
                    <div className="text-danger">{errors.inspector}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="state">Status</Label>
                  <Select
                    id="statusID"
                    options={status}
                    defaultValue={
                      status?.find(
                        option => option.value === selectedItem?.StatusID
                      ) || ""
                    }
                    onChange={option => setFieldValue("statusID", option?.value)}
                    onBlur={handleBlur("statusID")}
                    isClearable
                  />
                  {touched.statusID && errors.statusID && (
                    <div className="text-danger">{errors.statusID}</div>
                  )}
                </FormGroup>

                {values.statusID === "4" ? (
                  <FormGroup>
                    <Label for="comment">Comment to Client</Label>
                    <Input
                      type="textarea"
                      id="comment"
                      name="comment"
                      defaultValue={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.comment && !!errors.comment}
                    />
                    {touched.comment && errors.comment && (
                      <div className="text-danger">{errors.comment}</div>
                    )}
                  </FormGroup>
                ) : (
                  ""
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default UpdateModal
